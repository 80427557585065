import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"


const IndexPage = () => (
  
  <Layout>
      <Helmet>
      <html lang="de" />
          <meta charSet="utf-8" />
          <title>Impressum | Hausmeisterservice Gabriel Puscasu</title>
          <meta name="robots" content="index, follow"></meta>
        </Helmet>
        <section class="relative w-full bg-top bg-cover md:bg-center" id="fullhero" x-data="{ showMenu: false }">
    <div class="absolute inset-0 w-full h-full bg-gray-900 opacity-25"></div>
    <div class="absolute inset-0 w-full h-64 opacity-50 bg-gradient-to-b from-black to-transparent"></div>
    <div class="relative flex items-center justify-between w-full h-20 px-8">

        <Link to="/" class="relative flex items-center h-full pr-6 text-lg font-extrabold text-white"> <StaticImage
      src="../images/puscasu.svg"
      width={80}
      style={{ marginTop: `1.7rem` }}
      quality={100}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Logo Puscasu"
      
    />  </Link>

      
<nav class="flex flex-wrap items-center justify-between p-4">
        <div class="flex flex-shrink-0 mr-6"></div>
        <div class="block lg:hidden">
          <button class="navbar-burger flex items-center py-2 px-3 text-puscasug rounded border border-puscasug">
            <svg class="fill-current h-3 w-3" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
        
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </button>
        </div>
        <div class="navbar-menu hidden lg:flex lg:flex-grow lg:items-center w-full lg:w-auto">
          <div class="lg:ml-auto"><a class="block lg:inline-block mt-4 lg:mt-0 mr-10 text-white hover:text-puscasug" href="/#ueber">Über</a><a class="block lg:inline-block mt-4 lg:mt-0 mr-10 text-white hover:text-puscasug" href="/#leistungen">Leistungen</a></div>
          <div><a class="inline-block py-3 px-6 mt-4 lg:mt-0 leading-none text-white bg-puscasug hover:bg-indigo-700 font-semibold rounded shadow" href="/#kontakt">Kontakt</a></div>
        </div>
      </nav>
           

      
    </div>
   <div id="moin" class="relative z-10 max-w-6xl px-10 py-40 mx-auto">
        <div class="flex flex-col items-center h-full lg:flex-row">
            <div class="flex flex-col items-center justify-center w-full h-full lg:w-2/3 lg:items-start">
                <p  class="bg-puscasug inline-block w-auto px-3 py-1 mb-5 text-xs font-medium text-white uppercase  rounded-full bg-gradient-to-br">Rechtliches & Kontakdaten</p>
                <h1 class="font-extrabold tracking-tight text-center text-white text-7xl lg:text-left xl:pr-32">Impressum</h1>
            </div>
            <div class="w-full max-w-sm mt-20 lg:mt-0 lg:w-1/3">
     
            </div>
        </div>
    </div>

</section>

        
        <section id="impressum" class="py-20 bg-white">
    <div class="container max-w-6xl mx-auto">
    
           <p>Angaben gemäß § 5 TMG </p>
         <p>Hausmeisterservice Gabriel Puscasu</p>
           <p>   Marten-van-Achten Ring 21 </p>
             <p> 25836  Garding </p>
           <p> <strong>Vertreten durch: </strong></p>
            <p>   Gabriel Puscasu</p>
           <p> <strong>Kontakt:</strong> </p>
            <p> Telefon: 0176-32 548 658</p>
            <p>  E-Mail: <a href='mailto:info@puscasu.de'>info@puscasu.de</a></p>
           <p> <strong>Steuer-Nr.:</strong> 17/096/01056.</p>
           
        <p>  <strong>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</strong></p>
          <p>  Gabriel Puscasu </p>
           <p> Marten-van-Achten Ring 21</p>
           <p> 25836  Garding</p>
       
          <p> <strong>Bildnachweise:</strong> </p>
          <p>Kontakticon von <a href="https://www.flaticon.com/de/autoren/smashicons" title="Smashicons">Smashicons</a> / <a href="https://www.flaticon.com/de/" title="Flaticon">www.flaticon.com</a>, Bilder wenn nicht weiter angegeben von <Link to="https://maxschoenherr.com">Max Schönherr</Link> oder Unsplash.com</p>
     
  
  
           <p> <strong>Haftungsausschluss: </strong></p><p><strong>Haftung für Inhalte</strong></p>
          <p>  Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
              <p><strong>Haftung für Links</strong></p>
            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
       <p> <strong>Urheberrecht</strong></p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
       <p> <strong>Datenschutz</strong></p>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. 
           <p> Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich. </p>
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
           <p><strong>Hosting</strong></p>
           <p>Diese Webseite wird von Cloudflare Inc, 101 Towsend Street, San Francisco, CA 94107, Vereinigte Staaten von Amerika) durch den Dienst "Pages" gehostet.</p>
           <p>Auf den Servern des Hosters werden personenbezogene Daten gespeichert, die auf dieser Website erfasst werden. Hierbei kann es sich vorallem um die IP-Adresse, Meta- und Kommunikationsdaten, Kontaktdaten, Namen, Webseitenzugriffe und sonstige Daten, die über einen Websitenzugriff generiert werden.

Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).

Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.

<p>Vertrag über Auftragsverarbeitung</p>

Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über Auftragsverarbeitung mit Cloudflare geschlossen.</p>
           <p>Weitere Informationen zum Thema Datenschutz stellt Cloudflare unter <Link to="https://www.cloudflare.com/de-de/gdpr/introduction/">Cloudflares Selbstverpflichtung zur Einhaltung der DSGVO</Link> bereit</p>
            Impressum vom <a href="https://www.impressum-generator.de">Impressum Generator</a> der <a href="https://www.kanzlei-hasselbach.de/">Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und Familienrecht</a>
         
       
    </div>
</section>
     
   
   
  </Layout>
)

export default IndexPage
